




































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import PickupTextInput from '../components/PickupTextInput/index.vue'
import controller from '@/app/ui/controllers/PickupController'
import {
  PickupDetail,
  PickupDetailSnapshot,
  PickupInfo,
} from '@/domain/entities/Pickup'
import { Utils } from '@/app/infrastructures/misc'
import { EnumPickupFlag } from '@/app/infrastructures/misc/Constants/pickup'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import IconWeight from '@/app/ui/assets/ics_o_weight.vue'
import IconBookmark from '@/app/ui/assets/ics_o_bookmark.vue'
import PriorityIcon from '@/app/ui/assets/ics_f_priority-fast.vue'

@Component({
  components: {
    LoadingOverlay,
    PickupTextInput,
    Button,
    EmptyState,
    Badge,
    IconWeight,
    IconBookmark,
    PriorityIcon,
  },
})
export default class PickupDetailPage extends Vue {
  controller = controller

  enumPickupFlag = EnumPickupFlag
  utils = Utils

  pickupDetail: PickupDetail = {
    ...new PickupDetail(),
    account: {
      accountRole: '',
    },
    pickup: <PickupInfo>{
      fullname: '',
      address: '',
      phoneNumber: '',
    },
  }
  pickupSnapshotDetail: PickupDetailSnapshot[] = []

  created(): void {
    controller.getPickupDetail(this.$route.params.shipmentId)
    controller.getPickupSnapshotDetail(this.$route.params.shipmentId)
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }

  mounted(): void {
    if (this.isFromGlobalSearch) {
      this.onSetDisplayBreadcrumb(false)
    }
  }

  cancelReason = ''
  hasChanged = {
    cancelReason: false,
  }

  get isLoading(): boolean {
    return controller.isLoading
  }

  get shipmentIds(): string {
    if (
      this.pickupDetail.shipmentGroupIds &&
      this.pickupDetail.shipmentGroupIds.length > 0
    ) {
      return this.pickupDetail.shipmentGroupIds.join('; ')
    }
    return this.pickupDetail.shipmentId || '-'
  }

  get origin(): string {
    return `${this.pickupDetail.pickup?.district}, ${this.pickupDetail.pickup?.subDistrict}, ${this.pickupDetail.pickup?.village}`
  }

  get shipmentDate(): string {
    if (!this.pickupDetail.createdAt) {
      return ''
    }

    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(
        this.pickupDetail.createdAt,
        'DD MMM YYYY HH:mm Z'
      )
    )
  }

  get isCancelPickup(): boolean {
    return this.$route.meta.additional?.isCancel ? true : false
  }

  get markAsDoneRouteTo(): string {
    if (this.pickupDetail.flag === this.enumPickupFlag.CRROTW) {
      return 'PickupCRROTWMarkAsDone'
    }

    if (this.pickupDetail.flag === this.enumPickupFlag.DOLOTW) {
      return 'PickupCRROTWMarkAsDone'
    }

    return 'PickupCRRPICMarkAsDone'
  }

  get showMarkAsDoneButton(): boolean {
    if (
      this.pickupDetail.histories &&
      this.pickupDetail.histories.length !== 0
    ) {
      if (
        this.pickupDetail.flag === this.enumPickupFlag.CRRPIC &&
        !this.isNotPickupDetail
      ) {
        return true
      }

      if (
        this.pickupDetail.flag === this.enumPickupFlag.CRROTW &&
        !this.isNotPickupDetail
      ) {
        return true
      }

      if (
        this.pickupDetail.flag === this.enumPickupFlag.DOLOTW &&
        !this.isNotPickupDetail
      ) {
        return true
      }
    }

    return false
  }

  get isNotPickupDetail(): boolean {
    return (
      this.$route.name === 'PickupAdditionalTimeDetail' ||
      this.$route.name === 'PickupRadiusValidationShipmentDetail' ||
      this.$route.name === 'SearchShipmentDetailPage' ||
      this.$route.name === 'CancelValidationShipmentDetail'
    )
  }

  get isFromGlobalSearch(): boolean {
    return this.$route.name === 'SearchShipmentDetailPage'
  }

  get isShowEmptyState(): boolean {
    return (
      !this.isLoading &&
      this.isFromGlobalSearch &&
      !this.pickupDetail.shipmentId
    )
  }

  get chargeableWeight(): string {
    return this.pickupDetail.weight !== undefined
      ? `${Number((this.pickupDetail.weight / 1000).toFixed(1))} kg`
      : '-'
  }

  get productTypes(): string[] {
    const productTypes: string[] = this.isCorporate
      ? (<string[]>this.pickupDetail.productType)[0].split(',')
      : <string[]>this.pickupDetail.productType
    return <string[]>productTypes.map(type => {
        if (type === 'SAMEDAY') {
          return 'same day'
        }

        return type.toLocaleLowerCase()
      }) || []
  }

  get isCorporate(): boolean {
    return (<string>this.pickupDetail.shipmentId).substr(0, 2) === 'CP'
  }

  get canBeCancelled(): boolean {
    const shipmentId = this.pickupDetail.shipmentId || ''
    const flag = this.pickupDetail.flag || ''

    return (
      (flag === this.enumPickupFlag.CRRDON ||
        flag === this.enumPickupFlag.CRRPIC) &&
      (shipmentId.substr(0, 2) === 'AG' ||
        shipmentId.substr(0, 3) === 'ARA' ||
        shipmentId.substr(0, 3) === 'GAG' ||
        shipmentId.substr(0, 4) === 'GARA' ||
        shipmentId.substr(0, 2) === 'C1')
      // shipmentId.substr(0, 2) === 'CP' // hide for CP
    )
  }

  getShowGroupID(): boolean {
    return Boolean(
      this.pickupDetail.groupId &&
        this.pickupDetail.flag !== this.enumPickupFlag.CRRDON &&
        this.pickupDetail.flag !== this.enumPickupFlag.DOX
    )
  }

  private historyDate(date: string): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(date, 'DD MMMM YYYY HH:mm Z')
    )
  }

  private onSubmitCancel() {
    if (controller.pickupDetail.shipmentId) {
      controller.cancelPickup({
        shipmentId: controller.pickupDetail.shipmentGroupIds?.length
          ? controller.pickupDetail.shipmentGroupIds.join(',')
          : controller.pickupDetail.shipmentId,
        note: this.cancelReason,
      })
    }
  }

  private onSetDisplayBreadcrumb(isDisplayed: boolean): void {
    const breadcrumb = document.getElementById('breadcrumb')
    if (breadcrumb) {
      if (isDisplayed) {
        breadcrumb.style.display = 'flex'
      } else {
        breadcrumb.style.display = 'none'
      }
    }
  }

  convertCurrencyRp(amount: number) {
    return `Rp${Utils.currencyDigit(amount)}`
  }

  @Watch('controller.pickupDetail')
  onPickupDetailChanged(data: PickupDetail): void {
    this.pickupDetail = data
  }

  @Watch('controller.pickupSnapshotDetail')
  onPickupSnapshotDetailChanged(data: PickupDetailSnapshot[]): void {
    this.pickupSnapshotDetail = data
  }

  @Watch('controller.isCancelPickupSuccess')
  onIsCancelPickupSuccessChanged(data: boolean): void {
    this.$notify({
      title: 'Pickup Cancelled',
      text: `Order ${controller.pickupDetail.shipmentId} is successfully cancelled`,
      type: 'success',
      duration: 5000,
    })

    if (data) {
      this.$router.push({
        name: 'PickupCRRDON',
        query: { refetch: 'true' },
      })
    }
  }

  beforeDestroy(): void {
    controller.setIsCancelPickupSuccess(false)
    controller.setPickupDetail(new PickupDetail())
    this.onSetDisplayBreadcrumb(true)
  }
}
